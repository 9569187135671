import React from "react";
import "./App.css";
import LoginForm from "./Login.js";

import "bulma/css/bulma.css";

class App extends React.Component {
  render() {
    return (
      <div className="App p-6">
        <LoginForm />
      </div>
    );
  }
}

export default App;
