import React from 'react';
import './App.css';
import PaginationPage from "./Pagination/index.js";
import { Table } from 'reactstrap';

import 'bulma/css/bulma.css'

import sexTable from './data-json/sex.json';
import circumscription from './data-json/circumscription.json';
import tableTagTable from './data-json/tableTag.json';
import ethnicity from './data-json/puebloIndigena.json';
import region from './data-json/region.json';
import provincia from './data-json/provincia.json';
import comuna from './data-json/comuna.json';
import district from './data-json/district.json';
import geoData from './data-json/geoData.json';

import { Dropdown } from './components/Dropdown.jsx'

import Map from "./map";

const config = require('./config.js');

const clearFilter = {
      rut: '',
      name: '',
      sexId: -1,
      circumscriptionId: -1,
      tableTagId: -1,
      ethnicityId: -1,
      regionId: -1,
      provinceId: -1,
      communeId: -1,
      districtId: -1,
      onlyPhones: false,
}

class DataGrid extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      ...clearFilter,
      currentPage: 1,
      users: [],
      totalUsers: 0,
      loading: true,
      displayHeatMap: false
    }
  };

  nextpage = (pageNumber) => {
    this.setState({
      currentPage: pageNumber,
      users: []
    })
    this.getUsers(pageNumber);
  }
  tenChange = (pageNumber, isposOrneg) => {
    var finalPage;
    if (isposOrneg > 0) //+10 clicked
      finalPage = pageNumber + 10;
    else //-10 clicked
      finalPage = pageNumber - 10;
    this.setState({
      currentPage: finalPage,
      users: [],
    })
    this.getUsers(finalPage);
  }

  hundreadChange = (pageNumber, isposOrneg) => {
    var finalPage;
    if (isposOrneg > 0) //+100 clicked
      finalPage = pageNumber + 100
    else  //-100 Clicked
      finalPage = pageNumber - 100
    this.setState({
      currentPage: finalPage,
      users: [],
    })
    this.getUsers(finalPage);

  }
  dataRequest = (URL, methodType, params) => {
    return fetch(URL, {
      method: methodType,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(params)
    })
      .then(data => {
        return data.json()
      })
      .catch(err => {
        return err
      })
  }
  getUsers = (currentPage) => {
    this.setState({loading: true})
    console.log("Rendering Inside GetUsers")
    const queryParams = {};
    queryParams["page"] = currentPage; //Page Number
    queryParams["pagination"] = 8; //Number Of records on Page
    let endpoint = `${config.SERVER}/getUsers?s=${encodeURIComponent(this.state.sexId)}&ci=${encodeURIComponent(this.state.circumscriptionId)}&t=${encodeURIComponent(this.state.tableTagId)}&e=${encodeURIComponent(this.state.ethnicityId)}&re=${encodeURIComponent(this.state.regionId)}&p=${encodeURIComponent(this.state.provinceId)}&co=${encodeURIComponent(this.state.communeId)}&d=${encodeURIComponent(this.state.districtId)}&i=${encodeURIComponent(this.state.rut)}&n=${encodeURIComponent(this.state.name)}&op=${encodeURIComponent(this.state.onlyPhones)}`
    this.dataRequest(endpoint, 'POST', queryParams)
      .then(data => {
        console.log("Data FEtched ", data)
        this.setState({
          users: data.users,
          loading: false
        })
      })
      .catch(err => {
        console.log("Error In Fetching Users ", err)
        this.setState({
          users: [],
          loading: false
        })
      })
  }
  getUsersCount = () => {
    //Passing /1 as Backend Uses same query so if argument then it will return count
    let endpoint = `${config.SERVER}/getUsers/1?s=${encodeURIComponent(this.state.sexId)}&ci=${encodeURIComponent(this.state.circumscriptionId)}&t=${encodeURIComponent(this.state.tableTagId)}&e=${encodeURIComponent(this.state.ethnicityId)}&re=${encodeURIComponent(this.state.regionId)}&p=${encodeURIComponent(this.state.provinceId)}&co=${encodeURIComponent(this.state.communeId)}&d=${encodeURIComponent(this.state.districtId)}&i=${encodeURIComponent(this.state.rut)}&n=${encodeURIComponent(this.state.name)}&op=${encodeURIComponent(this.state.onlyPhones)}`
    
    this.setState({loading: true})

    this.dataRequest(endpoint, 'GET')
      .then(data => {
        console.log("data", data);
        this.setState({
          totalUsers: data.cnt,
          loading: true
        }, //call is for first page reeords only
          () => this.getUsers(this.state.currentPage))
      })
  }

  _handleFilter = () => {
    this.getUsersCount()
  }

  componentDidMount() {
    this.getUsersCount()
  }

  _handleRutChange = (event) => {
    this.setState({ rut: event.target.value });
  }

  _handleNameChange = (event) => {
    this.setState({ name: event.target.value });
  }

  _handleOnlyPhonesChange = (event) => {
    this.setState({ onlyPhones: !this.state.onlyPhones });
  }

  render() {
    let numberOfPages = 0;
    if (this.state.totalUsers % 8 === 0)
      numberOfPages = Math.floor(this.state.totalUsers / 8);
    else
      numberOfPages = Math.floor(this.state.totalUsers / 8) + 1;
    return (

      <div className="App">

        <div>
          <div className="columns is-desktop">
            
            <div className="column is-half">
              <div className="field is-horizontal">
                <div className="field-label is-normal">
                  <label className="label">Rut</label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <div className="control">
                      <div className="is-fullwidth">
                        <input className="input" type="text" placeholder="ingrese un rut"
                          value={this.state.rut}
                          onChange={this._handleRutChange} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="column is-half">
              <div className="field is-horizontal">
                <div className="field-label is-normal">
                  <label className="label">Nombre</label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <div className="control">
                      <div className="is-fullwidth">
                        <input className="input" type="text" placeholder="ingrese un nombre"
                          value={this.state.name}
                          onChange={this._handleNameChange} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="columns is-desktop">
            <Dropdown title='Sexo' data={sexTable} value={this.state.sexId} onChange={e => this.setState({sexId: e.target.value})}/>
            <Dropdown title='Circumscripción' data={circumscription} value={this.state.circumscriptionId} onChange={e => this.setState({circumscriptionId: e.target.value})}/>
            <Dropdown title='Mesa Tag' data={tableTagTable} value={this.state.tableTagId} onChange={e => this.setState({tableTagId: e.target.value})}/>
            <Dropdown title='Etnia' data={ethnicity} value={this.state.ethnicityId} onChange={e => this.setState({ethnicityId: e.target.value})}/>
          </div>
          <div className="columns is-desktop">
            <Dropdown title='Region' data={region} value={this.state.regionId} onChange={e => this.setState({regionId: e.target.value})}/>
            <Dropdown title='Provincia' data={provincia} value={this.state.provinceId} onChange={e => this.setState({provinceId: e.target.value})}/>
            <Dropdown title='Comuna' data={comuna} value={this.state.communeId} onChange={e => this.setState({communeId: e.target.value})}/>
            <Dropdown title='Distrito' data={district} value={this.state.districtId} onChange={e => this.setState({districtId: e.target.value})}/>
          </div>
          <div className="columns is-desktop">
            <label className="checkbox">
              <input type="checkbox" value={this.state.onlyPhones} checked={this.state.onlyPhones} onChange={this._handleOnlyPhonesChange} />
              &nbsp;Mostrar solamente ciudadanos con telefono
            </label>
          </div>
          <div className="field is-grouped is-grouped-centered">
            <div className="control">
              <button className="button is-link" onClick={() => this.getUsersCount() }>Aplicar Filtro</button>
            </div>
            <div className="control">
              <button className="button is-link is-light" onClick={() => this.setState(clearFilter) }>Limpiar</button>
            </div>
            <div className="control">
              <button className="button is-link is-light" onClick={() => this.setState({displayHeatMap: !this.state.displayHeatMap}) }>{ this.state.displayHeatMap ? 'Datos' : 'Mapa de Calor' }</button>
            </div>
          </div>

          {
            this.state.loading ?
              <div>
              <br/> <br/>
              <h2 className="subtitle">
descargando datos..
              </h2>
              <progress className="progress is-large is-danger" max="100">30%</progress>
              </div>
              : 
              <div>
              <br />
              <h2 className="subtitle">
                {new Intl.NumberFormat().format(this.state.totalUsers)} registros
              </h2>
              </div>
          } 

          {
             this.state.loading === false && this.state.users.length > 0 && this.state.displayHeatMap === true ?
              <Map data={geoData} center={{ lat: -33.594630, lng: -70.616152 }} />
               :
              null
          }

          {
             this.state.loading === false && this.state.users.length > 0 && this.state.displayHeatMap === false ?
              <Table responsive>
                <thead>
                  <tr>
                    <th>Nombre</th>
                    <th>Rut</th>
                    <th>Dv</th>
                    <th>Sexo</th>
                    <th>Direccion</th>
                    <th>Circunscripcion</th>
                    <th>Mesa</th>
                    <th>Mesa Tag</th>
                    <th>Pueblo Indigena</th>
                    <th>Pag</th>
                    <th>Region</th>
                    <th>Provincia</th>
                    <th>Comuna</th>
                    <th>Distrito</th>
                    <th>Telefono</th>
                    <th>Email</th>
                  </tr>
                </thead>
                <tbody>

                  {
                    this.state.users.map((data) => {
                      return (
                        <tr key={data._id}>
                          <th scope="row">{data.name}</th>
                          <td>{data._id}</td>
                          <td>{data.dv}</td>
                          <td>{data.sex[0].key}</td>
                          <td>{data.address}</td>
                          <td>{data.circumscription[0].key}</td>
                          <td>{data.tableNumberId}</td>
                          <td>{data.tableTagId === -1 ? null : data.tableTag[0].key}</td>
                          <td>{data.ethnicity[0].key}</td>
                          <td>{data.pageId}</td>
                          <td>{data.region[0].key}</td>
                          <td>{data.province[0].key}</td>
                          <td>{data.commune[0].key}</td>
                          <td>{data.district[0].key}</td>
                          <td>{data.phones.map(p => { return p._id + " " })}</td>
                          <td></td>
                        </tr>
                      )
                    })
                  }

                </tbody>
              </Table>
              : null
          }
          {
             this.state.loading === false && this.state.users.length === 0 ?
              "No hay datos"
              : null
          }
        </div>
        {
          this.state.loading === false &&
          this.state.totalUsers > 8 &&
          this.state.displayHeatMap === false &&
          <PaginationPage
            pages={numberOfPages}
            nextPage={this.nextpage}
            currentPage={this.state.currentPage}
            hundreadChange={this.hundreadChange}
            tenChange={this.tenChange}
          >
          </PaginationPage>
       }
      
      </div >
     
    );
  }
}

export default DataGrid;
