import React, { Component } from "react"
import PropTypes from 'prop-types'

export class Dropdown extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    data: PropTypes.array.isRequired,
    value: PropTypes.bigint.isRequired,
    onChange: PropTypes.func.isRequired,
  }

  render() {
    const { title, data, value, onChange } = this.props
    return(
      <div className="column is-one-quarter">
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">{title}</label>
          </div>
          <div className="field-body">
            <div className="field is-narrow">
              <div className="control">
                <div className="select is-fullwidth">
                  <select onChange={onChange} value={value}>
                    <option key={title + -1} value={-1}>- Seleccione -</option>
                    {
                      data.map(e => {return <option key={title + e._id} value={e._id}>{e.key}</option>})
                    }
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
