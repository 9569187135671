const getServer = () => {
  console.log("env", process.env.NODE_ENV);
  if (process.env.NODE_ENV === "production") {
    // return "http://datarev.cl/backend";
    return "http://198.199.78.99/backend";
  } else {
    return "http://localhost:5000/backend";
  }
};

const server = getServer();

module.exports = {
  SERVER: server,
};
