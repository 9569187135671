import React from 'react';
import DataGrid from "./DataGrid.js";

import "bulma/css/bulma.css";

class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      isLoading: false,
      isLoggedIn: false,
      info: null
    };
  }

  handleUsernameChange = (event) => {
    this.setState({ username: event.target.value });
  }

  handlePasswordChange = (event) => {
    this.setState({ password: event.target.value });
  }

  handleSubmit = (event) => {
    event.preventDefault();

    // Validate the form fields to make sure they are not empty
    if (!this.state.username || !this.state.password) {
      this.setState({ info: 'Username and password are required fields' });
      return;
    }

    // Show the loading indicator
    this.setState({ isLoading: true });

    // Log the user in using the username and password from state
    // This is where you would typically make an API call to a backend server
    // to log the user in, but for the purposes of this example we will
    // just simulate the process with a timeout.
    setTimeout(() => {
      if ((this.state.username === 'vdiazescobar@gmail.com' && this.state.password === 'password1234') ||
         (this.state.username === 'maleuy35@gmail.com' && this.state.password === '87%Z5TIdm632')) {
        this.setState({ isLoggedIn: true, isLoading: false, info: null });
        return;
      } else {
        this.setState({ isLoggedIn: false, isLoading: false, info: 'Username / password no validos.'});
        return;
      }
    }, 1000);

  }

  render() {
    // If the user is logged in, show a welcome message
    if (this.state.isLoggedIn) {
      return (
        <div>
        <label className="is-pulled-right">Bienvenido, {this.state.username}</label>;
        <DataGrid />
        </div>
      )
    }

    // If the user is logging in, show a loading indicator
    if (this.state.isLoading) {
      return <h1>Iniciando sesión...</h1>;
    }

    return (
      <form className="App" onSubmit={this.handleSubmit}>
        <h4>Inicio de sesión</h4>
        <div className="field"></div>
        <div className="field">
          <label className="label has-text-left" htmlFor="email">Email</label>
          <p className="control has-icons-left has-icons-right">
            <input className="input" type="email" placeholder="Email"
              id="email"
              value={this.state.username}
              onChange={this.handleUsernameChange} />
            <span className="icon is-small is-left">
              <i className="fas fa-envelope"></i>
            </span>
            <span className="icon is-small is-right">
              <i className="fas fa-check"></i>
            </span>
          </p>
        </div>
        <div className="field">
          <label className="label has-text-left" htmlFor="password">Password</label>
          <p className="control has-icons-left">
            <input className="input" type="password" placeholder="Password"
              id="password"
              value={this.state.password}
              onChange={this.handlePasswordChange} />
            <span className="icon is-small is-left">
              <i className="fas fa-lock"></i>
            </span>
          </p>
        </div>
        { this.state.info !== null ?
          <div className="notification is-warning is-light">
            <button className="delete"></button>
            {this.state.info}
          </div>
          :
            null
        }
        <div className="field">
          <p className="control">
            <button className="button is-success" type="submit">
              Login
            </button>
          </p>
        </div>
      </form>
    );
  }
}

export default LoginForm;
